import { lazy } from 'react';
import { useUiCtxSelector, useAppStateMatches } from '@/core';

const AddNewTrigger = lazy(
  () => import('@/domains/navs/components/add-new/add-new-trigger'),
);

export const SecondaryNavFooter = () => {
  const canEdit = useAppStateMatches('site.loaded.appClient.viewable.editable');
  const activeTopNavItem = useUiCtxSelector(({ nav }) => nav.activeTopNavItem);

  return (
    canEdit && (
      <AddNewTrigger
        menuPlacement="right"
        parentId={activeTopNavItem?.id}
        testId="add-entity--trigger--sidebar"
      />
    )
  );
};
