import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  getFlatDataFromTree,
  getTreeFromFlatData,
  TreeItem,
} from 'react-sortable-tree';
import { KnapsackNavItem } from '@knapsack/types';
import {
  useUiCtxSelector,
  useAppCanEdit,
  sendAppClientDataEvent,
  sendUiEvent,
} from '@/core';
import {
  useNavItems,
  useParentNavIds,
  usePrivateNavIds,
} from '@/domains/navs/hooks';
import './secondary-nav.scss';
import { secondaryNavRootKey } from '@/utils/constants';
import clsx from 'clsx';
import { layoutSpacing } from '@knapsack/toby';
import { getNavItemByPathName } from '@/utils/nav';
import { usePathname } from 'next/navigation';
import { NavTree } from '../components/nav-tree/nav-tree';

type KnapsackNavItemWithChildren = KnapsackNavItem & {
  children?: KnapsackNavItemWithChildren[];
  expanded?: boolean;
};

export const SecondaryNav: React.FC = React.memo(() => {
  const appCanEdit = useAppCanEdit();
  const activeTopNavItem = useUiCtxSelector(({ nav }) => nav.activeTopNavItem);
  const navItems = useNavItems({ includeHiddenItems: false });
  const privateNavIds = usePrivateNavIds();

  const activeNavItem = getNavItemByPathName({
    source: navItems,
    pathname: window.location.pathname,
  });
  const activeNavItemParentIds = useParentNavIds(activeNavItem?.id);

  const filteredNavItems = useMemo(() => {
    return navItems.filter((item) =>
      [item?.id, item?.parentId].every((id) => !privateNavIds.includes(id)),
    );
  }, [navItems, privateNavIds]);

  const [treeItems, setTreeItems] = useState<TreeItem[]>([]);
  const [allTreeItems, setAllTreeItems] = useState<TreeItem[]>([]);
  const isFirstRender = useRef(true);
  const pathname = usePathname();
  const prevPath = useRef(pathname);

  const setExpandedNavIds = useCallback(
    (items: TreeItem[]) => {
      return items.map((item) => {
        if (
          activeNavItemParentIds.includes(item.id) ||
          (activeNavItem?.id === item.id && item.children?.length > 0)
        ) {
          sendUiEvent({
            type: 'nav.setExpandedNavIds',
            add: item.id,
          });
        }
        if (item.children?.length > 0) {
          item.children = setExpandedNavIds(item.children as TreeItem[]);
        }
        return item;
      });
    },
    [activeNavItem?.id, activeNavItemParentIds],
  );

  useEffect(() => {
    const initialTreeData = getTreeFromFlatData({
      rootKey: activeTopNavItem?.id,
      flatData: filteredNavItems,
      getKey: (item) => item?.id,
      getParentKey: (item) => item?.parentId,
    });
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setExpandedNavIds(initialTreeData);
    }
    /**
     * ensures that the expanded nav items are set when the location
     * changes but the top nav item does not
     *  */
    if (pathname !== prevPath.current) {
      prevPath.current = pathname;
      setExpandedNavIds(initialTreeData);
    }

    setTreeItems(initialTreeData);
  }, [activeTopNavItem?.id, filteredNavItems, setExpandedNavIds, pathname]);

  useEffect(() => {
    const fullNavData = getTreeFromFlatData({
      rootKey: secondaryNavRootKey,
      flatData: navItems,
      getKey: (item) => item?.id,
      getParentKey: (item) => item?.parentId,
    });
    setAllTreeItems(fullNavData);
  }, [navItems]);

  return (
    <div
      className={clsx(
        'ks-test-sidebar',
        layoutSpacing({ paddingBottom: 'small' }),
      )}
    >
      <NavTree
        ariaLabel="Secondary"
        canEdit={appCanEdit}
        data-test-id="secondary-nav"
        handleNewTreeItems={(updatedTreeItems) => {
          if (updatedTreeItems && updatedTreeItems.length > 0) {
            setTreeItems(updatedTreeItems);
          }
        }}
        onMoveNode={({ treeData }) => {
          if (treeData && treeData.length > 0) {
            setTreeItems(treeData);
          }
          const allUpdatedNavItems = allTreeItems.map((item) => {
            if (item.id === activeTopNavItem?.id) {
              item.children = treeData;
            }
            return item;
          });

          const newFlatData = getFlatDataFromTree({
            treeData: allUpdatedNavItems,
            ignoreCollapsed: false,
            getNodeKey: (item: {
              treeIndex: number;
              node: KnapsackNavItemWithChildren;
            }) => {
              const nodeId = item?.node?.id;
              if (nodeId) return nodeId;
              console.error('Unable to find nav item id:', item);
              throw new Error(
                `Unable to find nav item id: "${JSON.stringify(item)}"`,
              );
            },
          }).map((flatDataItem) => {
            const { node, parentNode } = flatDataItem;
            const updatedNavItem: KnapsackNavItem = {
              id: node.id,
              name: node.name,
              path: node.path || '',
              parentId: parentNode ? parentNode.id : secondaryNavRootKey,
              hidden: node.hidden,
              minRoleNeeded: node.minRoleNeeded,
            };
            return updatedNavItem;
          });
          sendAppClientDataEvent({
            type: 'navs.secondary.update',
            payload: newFlatData,
          });
        }}
        treeItems={treeItems}
      />
    </div>
  );
});

export default SecondaryNav;
