'use client';

import {
  useUiCtxSelector,
  useIsAppEventAllowed,
  useUiStateMatches,
  useIsSmallScreen,
  useUserRole,
  sendUiEvent,
} from '@/core';
import { SectionLayout } from '@/core/layout/section-layout';
import { DocumentHeader } from '@/core/site/components';
import { SecondaryNav } from '@/domains/navs';
import { SecondaryNavFooter } from '@/domains/navs/secondary-nav/secondary-nav.footer';
import { SecondaryNavHeader } from '@/domains/navs/secondary-nav/secondary-nav.header';
import { userCanAccessItem } from '@/domains/navs/utils/nav-item-info';
import {
  useIsHomepage,
  useIsPluginRoute,
  useSetLastPath,
} from '@/utils/routes';
import { workspaceThemeVars } from '@/utils/ui-config';
import { Suspense } from 'react';
import DocumentSkeleton from './loading';

export default function DocsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const activeTopNavItem = useUiCtxSelector((ctx) => ctx.nav.activeTopNavItem);
  const allNavItems = useUiCtxSelector((ctx) => ctx.nav.source);
  const isEditing = useIsAppEventAllowed('site.previewOn');
  const isHomepage = useIsHomepage();
  const isPluginRoute = useIsPluginRoute();
  const isSidebarOpen = useUiStateMatches('sidebarDocument.opened');
  const isSmallScreen = useIsSmallScreen();
  const userRole = useUserRole();

  const hasChildPages = allNavItems.some((item) => {
    return item?.parentId === activeTopNavItem?.id;
  });

  const canHaveSidebar = !isHomepage && !isPluginRoute;

  const showSidebar =
    (isEditing && canHaveSidebar) ||
    (isEditing && hasChildPages) ||
    (!isEditing && canHaveSidebar && hasChildPages) ||
    (isSmallScreen && hasChildPages);

  const userCanAccessTopNavItem = userCanAccessItem({
    userRole,
    minRoleNeeded: activeTopNavItem?.minRoleNeeded,
  });

  useSetLastPath({ section: 'document' });

  return (
    <SectionLayout
      leftPanel={
        userCanAccessTopNavItem &&
        showSidebar && {
          ariaLabel: 'Secondary Navigation Panel',
          title: <SecondaryNavHeader />,
          opened: isSidebarOpen,
          children: <SecondaryNav key={activeTopNavItem?.id} />,
          onToggle: () => sendUiEvent('sidebarDocument.triggerToggle'),
          backgroundColor: workspaceThemeVars.secondaryNav.backgroundColor,
          footer: <SecondaryNavFooter />,
          testId: 'secondary-nav',
        }
      }
      header={<DocumentHeader />}
    >
      <Suspense fallback={<DocumentSkeleton />}>{children}</Suspense>
    </SectionLayout>
  );
}
